
@keyframes pointChange {
    0% {
      width: 1.5%;
      aspect-ratio: 1;
      border-radius: 50%;
      background: red;
    }
    50% {
      width: 3%;
      aspect-ratio: 1;
      border-radius: 50%;
      background: rgb(16, 243, 8);
    }
    100% {
      width: 1.5%;
      aspect-ratio: 1;
      border-radius: 50%;
      background: blue;
    }
  }
  