.section {
  max-width: 40rem;
  margin: 1rem auto;
  background-color: white;
  padding: 1rem;
  border-radius: 3.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  text-align: center; 

}

.section1 {
  max-width: 50%;
  max-height: 200px;
  margin: 1rem auto;
  background-color: white;
  padding: 1rem;
  border-radius: 4rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  text-align: center; 
}
