table {
  width: 100%;
}
tbody {
  max-height: 450px; 
  overflow-y: auto;
  display: block; 
}
body {
  font-family: sans-serif;
  font-weight: 100;
}

input[type="text"] {
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
  width: 300px;
}

input[type="text"]:focus {
  background-color: rgba(210, 185, 185, 0.832);
}
 
.SearchBox1{
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: black;
} 
.searchBox {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: black;
}

.active {
  font-size: 1.2em;
  font-weight: bold;
}

.Selected {
  font-weight: bold;
}

